.login-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  background-image: url("../../home.png");
  background-size: cover;
}

.login-page h1 {
  margin: 32px 0;
  font-size: 24px;
}

.login-page > div {
  flex: 1 1;
  width: 50%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.login-page .input-container {
  margin-bottom: 24px;
}

.login-page > div:first-child {
  align-items: center;
}

.login-page > div:first-child > div {
  width: 60%;
  max-width: 400px;
}

.login-page > div input {
  width: 100%;
}

.login-page .img {
  background: url('../../home.png'), lightgray no-repeat;
  width: 100%;
  height: 100vh;
  background-position: 65% center;
}

.login-page .links-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.login-page .login-div {
  background-color: #F3F3F3;
  padding: 60px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.centerText{
  text-align: center;
}

.powered{
  margin-top: 24px;
}