.gestion-envios-page .title {
  margin-bottom: 20px;
}

.gestion-envios-page .main-content {
  display: flex;
  padding: 30px 24px 0px 24px;
  flex-direction: column;
  align-items: flex-start;
}

.gestion-envios-page .pagination-container {
  position: fixed;
  padding-right: 42px;
}

.gestion-envios-page .navigation-bar {
  background: rgb(11,46,19);
  background: linear-gradient(90deg, #0b2e1399 6%, rgba(0,212,255,0) 19%);
}