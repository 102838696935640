.descarga-ficheros-page .main-content {
  display: flex;
  padding: 30px 24px 0px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 29px;
}

.descarga-ficheros-page .container {
  display: flex;
  padding: 30px 0px;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  border-radius: 8px;
  border: 1px solid var(--Gray-200, #EAECF0);
  width: 100%;
}

.descarga-ficheros-page .navigation-bar {
  background: rgb(11,46,19);
  background: linear-gradient(90deg, #0b2e1399 6%, rgba(0,212,255,0) 19%);
}

.descarga-ficheros-page .container > div {
  display: flex;
  gap: 12px;
  flex-direction: column;
  width: 100%;
  max-width: 290px;
}

.descarga-ficheros-page .container input {
  width: 100%;
}

.descarga-ficheros-page .container .success-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.descarga-ficheros-page .container .error-container {
  gap: 20px;
}


.descarga-ficheros-page .container .success-container img {
  width: 46px;
  height: 46px;
  border-radius: 23px;
  background: var(--Amarillo-claro, #FFEFD6);
}

.descarga-ficheros-page .container .success-container p {
  color: var(--Azul-oscuro, #000F24);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.descarga-ficheros-page .container .success-container Button {
  width: 100%;
  padding: 10px 32px;
}

.descarga-ficheros-page .container .error-container > div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;
}