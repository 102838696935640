:root {
  --Amarillo-claro: #FFEFD6;
  --Amarillo-CTA: #6D6E6D;
  --Red: #EB5757;
  --Green: #27AE60;

  --Gris-80: #808080;
  --gray-100: #F2F4F7;
  --Gray-200: #EAECF0;
  --gray-300: #D0D5DD;
  --gray-500: #667085;
  --gray-600: #475467;
  --gray-700: #344054;
  --gray-800: #1D2939;
  --gray-900: #101828;

  --base-white: #FFF;

  --Azul-medio: #013380;
  --Azul-oscuro: #000F24;

  --success-50: #ECFDF3;
  --success-200: #ABEFC6;
  --success-500: #17B26A;
  --success-700: #067647;

  --warning-50: #FFFAEB;
  --warning-200: #FEDF89;
  --warning-500: #F79009;
  --warning-700: #B54708;

  --error-50: #FEF3F2;
  --error-200: #FECDCA;
  --error-500: #F04438;
  --error-700: #B42318;
}

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
}

h1 {
  color: var(--gray-900, #101828);
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
}

h2 {
  color: var(--gray-900, #101828);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin: 0;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

.label {
  display: block;
  color: var(--gray-700, #344054);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.flex {
  display: flex;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border: none;
  background: none;

  color: var(--Azul-medio, #013380);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-decoration: none;
}

.error-msg {
  color: var(--Red, #EB5757);
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
}

.title {
  display: flex;
  align-items: center;
  gap: 8px;
}

.title img {
  width: 24px;
  height: 24px;
}

.rdt_Table {
  border: 1px solid var(--gray-200, #EAECF0);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.rdt_TableHeadRow {
  color: var(--Gray-600, #475467);
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
}

.dYHiBl,
.bkAuMe {
  background: var(--Gray-50, #F9FAFB);
}

.iRCIsJ:nth-of-type(n) {
  background: var(--Amarillo-claro, #FFEFD6) !important;
}

.rdt_Table .centered-header {
  justify-content: center !important;
  margin: auto;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: var(--Amarillo-claro, #FFEFD6);
  color: #000;
  text-align: center;
  padding: 15px 10px;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}


.tabs-container {
  display: flex;
  width: fit-content;

  color: var(--Azul-oscuro, #000F24);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.408px;
  margin-bottom: 20px;
}

.tabs-container .tab-seleccionada,
.tabs-container .tab-no-seleccionada {
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  border: none;
  gap:12px;

  color: var(--Gris-80, #808080);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.408px;
}

.tabs-container .tab-seleccionada {
  background: var(--Amarillo-claro, #FFEFD6);
  border-radius: 8px;
  color: var(--Azul-oscuro, #000F24);
}

.tabs-container .tab-no-seleccionada {
  background: var(--base-white , #ffffff);
}
/*
.marginTop100{
  margin-top: 100px;
}*/

.cabecera{
  background: url('../home2.png') lightgray;
  background-position: center 70%;
  background-size: cover;
}

.cabecera .title h2 {
  color: #fff;
}
.cabecera .title{
  padding: 30px;
  background: rgb(11,46,19);
  background: linear-gradient(90deg, #0b2e1399 6%, rgba(0,212,255,0) 19%);
}

.poweredBaner{
  text-align: center;
  position: absolute;
  bottom: 90px;
  left: 69px;
}