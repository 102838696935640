/* Estilos para Navbar */
.navigation-bar {
  display: flex;
  padding: 14px 24px;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.navigation-bar .logo-img {
  height: 18px;
  margin-left: 24px;
  cursor: pointer;
}

.navigation-bar .left-icon,
.navigation-bar .right-icon {
  cursor: pointer;
}

.navigation-bar .right-icon img {
  width: 24px;
  height: 24px;
  border-radius: 100%;
}

.logout-modal {
  border: 1px solid var(--Gray-200, #EAECF0);
  border-radius: 10px;
  position: absolute;
  background-color: var(--base-white);
  right: 24px;
  top: 55px;
  z-index: 100000;
}

.logout-modal > div {
  padding: 12px 16px;
  display: flex;
  gap: 12px;
  align-items: center;
}

.logout-modal > div div:first-of-type {
  color: var(--Gray-700, #344054);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.logout-modal > div div:first-of-type p {
  margin: 0;
}

.logout-modal > div div:first-of-type p:last-of-type {
  color: var(--Gray-600, #475467);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.logout-modal > div:first-of-type img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.logout-modal img {
  width: 16px;
  height: 16px;
}

.logout-modal .logout-div {
  border-top: 1px solid var(--Gray-200, #EAECF0);
}

.logout-modal .logout-div a {
  color: #344054;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

/* Estilos para el menú de Navbar */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  z-index: 10;
}

.navigation-bar .left-icon{
}

.menu {
  position: fixed;
  top: 56px;
  left: -400px;
  width: 250px;
  height: 100%;
  background-color: var(--base-white);
  color: var(--Gray-600, #475467);
  transition: left 0.3s ease;
  z-index: 100000;
  padding: 16px 32px;
}

.menu img {
  cursor: pointer;
}

.menu.open {
  left: 0;
}

.menu > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu ul {
  list-style: none;
  padding: 0;
}

.menu li {
  padding: 12px 8px;
  margin-bottom: 2px;
}

.menu li.active {
  border-radius: 6px;
  background: #EAECF0;
}

.menu li:hover {
  border-radius: 6px;
  background: #EAECF0;
}

.menu li img {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.menu a {
  text-decoration: none;
  display: flex;
  align-items: center;

  color: var(--gray-700, #344054);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.menu button {
  margin-top: 20px;
  padding: 10px;
  background-color: #555;
  color: white;
  border: none;
  cursor: pointer;
}

.menu li a img:last-child {
  display: none;
}
.menu li a:hover img:last-child {
  display: block;
}
.menu li a:hover img:first-child {
  display: none;
}

.menu li.active a img:last-child {
  display: block;
}
.menu li.active a img:first-child {
  display: none;
}
