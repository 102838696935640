.home-page .main-image {
  height: 130px;
  display: flex;
  align-items: center;
}

.home-page .main-content {
  padding: 60px;
}

.home-page .navigation-bar {
  background: #0b2e1399;
}

.home-page .main-image{
  background: rgb(11,46,19);
  background: linear-gradient(90deg, #0b2e1399 6%, rgba(0,212,255,0) 19%);
}


.home-page h2 {
  color: var(--gray-900, #101828);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 20px;
}

.home-page .module-container {
  display: flex;
  gap: 24px;
  justify-content: center;
}

.home-page .module-container img {
  height: 33px;
}

.home-page .module-container a {
  display: flex;
  height: 151px;
  max-width: 300px;
  padding: 20px 20px 37px 20px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  box-sizing: border-box;

  border-radius: 8px;
  border: 1px solid var(--Gray-200, #EAECF0);
  background: #FFF;

  color: var(--Azul-oscuro, #000F24);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-decoration: none;
  text-align: center;
}

.home-page .logo-img{
  display: none;
}
.home-page .logo-img2{
  padding-left: 30px;
}

.module-container a img:last-child {
  display: none;
}
.module-container a:hover img:last-child {
  display: block;
}
.module-container a:hover img:first-child {
  display: none;
}
